import logo from '../kukacka.png';
import './Home.css';

function test() {
    return(
    <div className="test">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Development in progress...
        </p>
      </header>
    </div>
  );
}

export default test;